import { render, staticRenderFns } from "./Collect5.vue?vue&type=template&id=27fcbfd3&scoped=true&lang=pug&"
import script from "./Collect5.vue?vue&type=script&lang=ts&"
export * from "./Collect5.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27fcbfd3",
  null
  
)

export default component.exports